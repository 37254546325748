import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import LottieContainer from '../../Common/Components/LottieContainer';

const SemesterCompletePanel = (
  {
    downloadUrl,
    shareUrl,
    progress,
    user_id,
    allContentCompleted,
    tabType,
  }
) => {
  const { t } = useTranslation('LearnDashboard');
  const sharable = shareUrl !== null;

  const renderButtons = () => {
    const buttons = [];

    if (sharable && shareUrl != null) buttons.push(shareButton);
    if (downloadUrl != null) buttons.push(downloadButton);
    if (allContentCompleted) buttons.push(exploreButton);

    if (buttons.length === 3) buttons.splice(1, 1);

    return buttons.map((button, index) => button(index === 0 ? 'primary' : 'secondary'));
  };

  const shareButton = buttonType => (
    <a
      onClick={() => handleLinkedInSharing(shareUrl)}
      key="share-diploma"
      className={`Button Button--${buttonType} Button--withIcon`}
      id="share-diploma"
    >
      <span className="Button-text">{t('shareDiploma')}</span>
      <i className="Button-icon fa fa-linkedin-square" />
    </a>
  );

  const downloadButton = buttonType => (
    <a
      href={downloadUrl}
      id="download-diploma"
      className={`Button Button--${buttonType} download`}
    >
      <span>{t('downloadDiploma')}</span>
    </a>
  );

  const exploreButton = buttonType => (
    <a key="explore-paths" className={`Button Button--${buttonType}`} href="/learning_paths">
      <span>{t('exploreLearningPaths')}</span>
    </a>
  );

  if (progress.all_courses_optional && downloadUrl === null) {
    return (
      <div className="empty-panel m-bottom-m">
        <h2>{t('congratulationsExempt')}</h2>
        <p>{t('assignedCompleteExempt')}</p>

        <a className="button download blue" href="/library">{t('exploreLibrary')}</a>
      </div>
    );
  }
  return (
    <div className="empty-panel has-image m-bottom-m">
      <LottieContainer
        className="empty-panel-image"
        src="/assets/lottie/diploma-celebrate.json"
        cover={false}
        loop={false}
        onAnimationComplete={() => {
        }}
      />
      <div className="empty-panel-content">
        <h2>{t('congratulations')}</h2>
        <p>
          {tabType === 'learning_path' ? t('learningPathComplete') : t('assignedComplete')}
&nbsp;
          <Trans t={t} i18nKey={downloadUrl ? 'viewDiploma' : 'diplomaPending'}>
            You can view your certificate on your
            <a href={`/certificates?user_id=${user_id}`}>Certificates and Diplomas dashboard</a>
            .
          </Trans>
        </p>

        {renderButtons()}

      </div>
    </div>
  );
};

const handleLinkedInSharing = sharingUrl => {
  const encodedUrl = encodeURIComponent(sharingUrl);
  window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`, 'sharer', 'toolbar=0, status=0, width=626, height=436');
  return false;
};

export default SemesterCompletePanel;
