import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import VideoModal from '../../DesignSystem/VideoModal';
import VideoPlayer from '../../VideoPlayer/VideoPlayer';
import client from '../../../lib/api/axiosClient';

const ContentActions = ({
  content,
  apiCalls,
  reloadCallback,
}) => {
  const { t } = useTranslation('LearnDashboard');
  const startLink = `/${content.content_type.toLowerCase()}s/${content.slug}`;
  const examLink = `/exam_submissions/new?course_id=${content.id}`;
  const [currentAction, setCurrentAction] = useState();
  const [watchVcRecording, setWatchVcRecording] = useState(false);

  useEffect(() => {
    setCurrentAction(null);
  }, [content.skipped]);

  const buttons = {
    downloadCertificate: () => {
      if(!content.certificate_url) return <></>;
      return (
        <a
          key={`${content.content_type}-${content.id}-button-certificate`}
          className="content-action button small with-icon"
          href={content.certificate_url}
        >
          {t('downloadCertificate')}
          <i
            className="fa fa-download"
          />
        </a>
      );
    },
    goToContent: (buttonText, extraClass = '') => (
      <a
        key={`${content.content_type}-${content.id}-button-start`}
        className={`content-action button small with-icon ${extraClass}`}
        href={startLink}
      >
        {buttonText}
        <i className="fa fa-play-circle" />
      </a>
    ),
    joinSession: () => {
      if (content.is_session_finished) return;

      const canJoin = moment().add(15, 'minutes').isAfter(content.begins_at);
      const hasSessionStarted = moment().isAfter(content.begins_at);
      const formattedBeginsAt = moment(content.begins_at).format('DD MMM, YYYY hh:mm a');
      const toolTip = hasSessionStarted ? t('sessionStartedAt', { startedAt: formattedBeginsAt }) : t('sessionBeginsAt', { beginsAt: formattedBeginsAt });
      const sessionUrl = canJoin ? content.action_url : '#';
      const inactiveClass = !canJoin ? 'is-inactive' : '';
      const handleJoinSession = e => {
        if (!canJoin) {
          e.preventDefault();
          return false;
        }
      };

      return (
        <>
          <a
            key={`${content.content_type}-${content.id}-button-start`}
            className={`content-action Button Button--primary ${inactiveClass}`}
            href={sessionUrl}
            target="_blank"
            rel="noreferrer"
            onClick={e => handleJoinSession(e)}
            data-tip
            data-for={`joinSession-${content.id}`}
          >
            <span>{t('joinSession')}</span>
            <i className="custom-icon icon-color-blue fa fa-calendar-check-o" />
          </a>
          {toolTip && (
            <ReactTooltip id={`joinSession-${content.id}`} place="top" type="light" effect="solid">
              {toolTip}
            </ReactTooltip>
          )}
        </>
      );
    },
    goToExam: (buttonText, extraClass = '') => (
      <a
        key={`${content.content_type}-${content.id}-button-exam`}
        className={`content-action button small with-icon ${extraClass}`}
        href={examLink}
      >
        {buttonText}
        <i className="fa fa-play-circle" />
      </a>
    ),
    additional: (extraClass = '') => {
      if (content.learning_context === 'saved') {
        return (
          <a
            key={`${content.content_type}-${content.id}-button-unsave`}
            className={`content-action button small ${extraClass}`}
            onClick={() => {
              setCurrentAction('removingFromSaved');
              apiCalls.removeFromSaved(content)
                .then(() => {
                  reloadCallback()
                    .then(setCurrentAction(null));
                });
            }}
          >
            {t('removeFromSaved')}
          </a>
        );
      } if (content.learning_context === 'semester' && content.optional) {
        if (content.skipped === true) {
          return (
            <a
              key={`${content.content_type}-${content.id}-button-restore`}
              className={`content-action button small ${extraClass}`}
              onClick={() => {
                setCurrentAction('restoring');
                apiCalls.restoreContent(content)
                  .then(() => {
                    reloadCallback()
                      .then(setCurrentAction(null));
                  });
              }}
            >
              {t('returnToDo')}
            </a>
          );
        }
        return (
          <a
            key={`${content.content_type}-${content.id}-button-skip`}
            className={`content-action button small ${extraClass}`}
            onClick={() => {
              setCurrentAction('skipping');
              apiCalls.skipContent(content)
                .then(() => {
                  reloadCallback()
                    .then(setCurrentAction(null));
                });
            }}
          >
            {t('markAsCompleted')}
          </a>
        );
      }
    },
    downloadResource: () => {
      if (!content.resource_url) return;
      return (
        <a
          key={`${content.content_type}-${content.id}-button-resource`}
          className="content-action Button Button--secondary"
          href={content.resource_url}
        >
          <span>{t('downloadResource')}</span>
          <i
            className="custom-icon fa fa-download"
          />
        </a>
      );
    },
    watchRecording: () => {
      if (!content.is_session_finished) return;

      const handleWatchRecording = e => {
        e.preventDefault();
        client
          .put(`/virtual_classrooms/${content.id}/mark_completed`)
          .then(() => {
            setWatchVcRecording(true);
          });
      };

      const handleCloseModal = () => {
        window.location.reload();
      };

      return (
        <>
          <VideoModal show={watchVcRecording} onCloseModal={handleCloseModal}>
            <div className="content">
              <div className="player-container">
                <VideoPlayer playbackId={content.video_playback_id} />
              </div>
            </div>
          </VideoModal>
          <a
            key={`${content.content_type}-${content.id}-button-start`}
            className="content-action Button Button--primary"
            onClick={handleWatchRecording}
          >
            <span>{t('watchRecording')}</span>
            <i className="custom-icon icon-color-blue fa fa-play-circle" />
          </a>
        </>
      );
    },
  };

  const states = {
    projectComplete: () => [
      buttons.downloadCertificate(),
      buttons.goToContent(t('reviewProject'), 'white-bordered'),
      buttons.additional('white-bordered'),
    ],
    courseComplete: () => [
      buttons.downloadCertificate(),
      buttons.goToContent(t('rewatch'), 'white-bordered'),
      buttons.additional('white-bordered'),
    ],
    start: () => [
      buttons.goToContent(t('start')),
      buttons.additional('white-bordered'),
    ],
    takeExam: () => [
      buttons.goToExam(t('takeExam')),
      buttons.goToContent(t('rewatch'), 'white-bordered'),
      buttons.additional('white-bordered'),
    ],
    continue: () => [
      buttons.goToContent(t('resume')),
      buttons.additional('white-bordered'),
    ],
    virtualClassroom: () => [
      buttons.joinSession(),
      buttons.watchRecording(),
      buttons.downloadResource(),
    ],
  };

  const actionInProgress = () => (
    <span className="pending-action">
      <i className="fa fa-circle-o-notch fa-spin" />
&nbsp;
      {t(currentAction)}
      ...
    </span>
  );

  const getButtons = () => {
    if (content.content_type === 'VirtualClassroom') return states.virtualClassroom();
    if (content.completed === true) {
      if (content.content_type === 'Project') return states.projectComplete();
      return states.courseComplete();
    }
    const watchedExaminableCourse = content.content_type === 'Course' && content.percent_watched === 100 && content.has_live_exam;
    if (watchedExaminableCourse) return states.takeExam();
    if (content.progress === 0) return states.start();
    return states.continue();
  };

  if (currentAction != null) return actionInProgress();

  return <>{getButtons()}</>;
};

export default ContentActions;
