import React, { useState } from 'react';
import _ from 'lodash';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';

import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import { School, ImportContacts } from '@mui/icons-material';

import { Link } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import KubicleMuiTheme from '../../../lib/utils/muiTheme';

const HorizontalNavbar = ({ user_nav_links }) => {
  const userNavLinks = user_nav_links;
  const myLearningNavLink = _.find(userNavLinks, { id: 'my_learning' });
  const diplomasNavLink = _.find(userNavLinks, { id: 'diplomas' });
  const settingsNavLink = _.find(userNavLinks, { id: 'settings' });

  const menuId = 'primary-search-account-menu';
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = event => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const currentUserInitials = () => `${KUBE.currentUserName.split(' ')[0][0]}${KUBE.currentUserName.split(' ')[1][0]}`;

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuList sx={{ width: 200, maxWidth: '100%' }}>
        {settingsNavLink && (
          <Link href={settingsNavLink.url} onClick={handleMenuClose}>
            <MenuItem>
              <ListItemIcon>
                <SettingsIcon fontSize="small" sx={{ color: KubicleMuiTheme.palette.icon.light }} />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </MenuItem>
          </Link>
        )}
        <Link href="https://support.kubicle.com" onClick={handleMenuClose} target="_blank" rel="noreferrer">
          <MenuItem>
            <ListItemIcon>
              <HelpIcon fontSize="small" sx={{ color: KubicleMuiTheme.palette.icon.light }} />
            </ListItemIcon>
            <ListItemText>Support</ListItemText>
          </MenuItem>
        </Link>
        <Divider />
        <form method="POST" action="/sign-out?u=true">
          <input
            type="hidden"
            name="authenticity_token"
            value={document?.querySelector('meta[name=csrf-token]')?.content}
          />
          <input type="hidden" name="_method" value="DELETE" />
          <MenuItem>
            <ListItemIcon>
              <LogoutIcon fontSize="small" sx={{ color: KubicleMuiTheme.palette.icon.light }} />
            </ListItemIcon>
            <Button
              variant="text"
              size="small"
              type="submit"
              sx={{
                padding: 0,
                '&:hover': {
                  background: 'transparent',
                  boxShadow: 'inherit',
                  color: KubicleMuiTheme.palette.primary.main,
                },
              }}
            >
              <ListItemText>Sign Out</ListItemText>
            </Button>
          </MenuItem>
        </form>
      </MenuList>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        color="white"
        position="static"
        sx={{
          boxShadow: 'none',
          zIndex: 100,
        }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Box>
            {myLearningNavLink && (
              <Tooltip title="Learn/Discover" arrow>
                <IconButton href={myLearningNavLink.url} size="large">
                  <ImportContacts sx={{ color: KubicleMuiTheme.palette.icon.light }} />
                </IconButton>
              </Tooltip>
            )}
            {diplomasNavLink && (
              <Tooltip title="Diplomas" arrow>
                <IconButton href={diplomasNavLink.url} size="large" sx={{ mr: 1 }}>
                  <School sx={{ color: '#FC9217' }} />
                </IconButton>
              </Tooltip>
            )}
            <IconButton
              size="small"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <Avatar sx={{ bgcolor: '#3BADA7' }} fontSize="inherit">
                {currentUserInitials()}
              </Avatar>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
};

export default HorizontalNavbar;
