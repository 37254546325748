import React, { useState } from 'react';

const VideoModal = ({
  show = false,
  children,
  onCloseModal,
}) => {
  if (!show) return null;

  const handleCloseModal = e => {
    e.preventDefault();
    e.stopPropagation();
    onCloseModal();
  };

  return (
    <div className="Modal VideoModal">
      <div className="Modal-panel">
        <a className="Modal-closeButton" onClick={e => handleCloseModal(e)}>
          <i className="fa fa-times" title="Close" />
        </a>
        {children}
      </div>
    </div>
  );
};

export default VideoModal;
